import React, { createContext, useContext, useEffect, useState } from "react"

import { collection, doc, getDoc, getDocs } from "@firebase/firestore"
import { onIdTokenChanged, User } from "@firebase/auth"
import { firebaseAuth, firebaseDatabase } from "./firebaseConfig"

import { enqueueSnackbar } from "notistack"

import { FirebaseUser, Role } from "./service"
import { ScheduleOverviewMutable } from "../schedule/service"

import { Organization } from "../serviceImpl"
import { Project } from "../projects/serviceImpl"

const FirebaseUserContext = createContext<FirebaseUser | null>(null)

export const FirebaseUserProvider = ({ children }: any) => {
  const [role, setRole] = useState<Role | null>(null)
  const [organization, setOrganization] = useState<Organization | null>(null)
  const [projects, setProjects] = useState<Array<Project>>([])
  const [scheduleSelected, setScheduleSelected] = useState<ScheduleOverviewMutable | null>(null)
  const [user, setUser] = useState<User | null>(null)
  const [isOrganizationLoading, setIsOrganizationLoading] = useState<boolean>(true)

  const handleProjectDelete = (id: string) => {
    console.log(id)
  }
  const handleUserDelete = () => {
    if (user)
      user.delete().then(() => {
        handleUserSignOut()
      })
        .catch((error) => {
          if (error.code === "auth/requires-recent-login")
            handleUserSignOut()
          enqueueSnackbar(
            "You need to sign back in to delete your account forever.",
            { key: "delete", variant: "error", preventDuplicate: true }
          )
        })
  }
  const handleUserSignOut = () => {
    setUser(null)
    firebaseAuth.signOut()
  }

  useEffect(() => {
    onIdTokenChanged(firebaseAuth, newUser => {
      setUser(newUser)
    })
  }, [])

  useEffect(() => {
    if (user) {
      const userRef = doc(firebaseDatabase, "user", user.uid)

      getDoc(userRef).then((result) => {
        const userData = result.data()

        if (!userData)
          return

        const { settings } = userData

        console.log(settings)
      })

      const userOrganizationRef = collection(userRef, "organization")

      getDocs(userOrganizationRef).then((organizations) => {
        if (organizations.empty)
          return

        organizations.forEach((organization) => {
          const organizationRef = doc(firebaseDatabase, "organization", organization.id)

          getDoc(organizationRef).then((result) => {
            const data = result.data()

            if (!result.id || !data)
              return

            setOrganization(new Organization(
              data.name,
              data.status,
              data.license,
              data.logoURL,
              result.id
            ))

            const userOrganizationProjectsRef = collection(organization.ref, "project")

            getDocs(userOrganizationProjectsRef).then((projects) => {
              if (projects.empty)
                return

              setProjects([])

              projects.forEach((project) => {
                const projectRef = doc(collection(organizationRef, "project"), project.id)

                return getDoc(projectRef).then(async (project) => {
                  const userOrganizationProjectsSchedulesRef = collection(project.ref, "schedule")

                  const schedules = await getDocs(userOrganizationProjectsSchedulesRef).then(async (schedulesSnapshot) => {
                    if (schedulesSnapshot.empty) {
                      return []
                    }

                    const userSchedules: Array<ScheduleOverviewMutable> = await Promise.all(
                      schedulesSnapshot.docs.map(async (schedule) => {
                        const scheduleRef = doc(collection(projectRef, "schedule"), schedule.id)
                        const scheduleDoc = await getDoc(scheduleRef)
                        const { name, status } = scheduleDoc.data() as ScheduleOverviewMutable

                        return {
                          id: schedule.id,
                          name,
                          status
                        }
                      })
                    )

                    return userSchedules
                  })

                  const { file, name, status } = project.data() as Project

                  const newProject = new Project({
                    id: project.id,
                    file,
                    name,
                    schedules: schedules?.filter(Boolean) || [],
                    status
                  })

                  setProjects(prevState => ([
                    ...prevState,
                    newProject
                  ]))
                })
              })
            })
          }).catch((error) => {
            if (error.code === "permission-denied") {
              enqueueSnackbar("Your organization membership has been revoked.", {
                autoHideDuration: 15000,
                key: "membership-revoked",
                variant: "error",
                preventDuplicate: true,
              })
            }
          })
        })


        setIsOrganizationLoading(false)
      })
    }
  }, [user])

  useEffect(() => {
    if (!organization || !user)
      return

    user.getIdTokenResult().then((result) => {
      // @ts-ignore
      setRole(result.claims.organization[organization.id].role)
    })
  }, [organization, user])

  return (
    <FirebaseUserContext.Provider
      value={{
        handleProjectDelete,
        handleUserSignOut,
        handleUserDelete,
        isOrganizationLoading,
        organization,
        projects,
        role,
        scheduleSelected,
        setScheduleSelected,
        user
      }}
    >
      {children}
    </FirebaseUserContext.Provider>
  )
}

export const useFirebaseUser = () => {
  const context = useContext(FirebaseUserContext)

  if (!context) {
    throw new Error("useFirebaseUser must be used within a FirebaseUserProvider")
  }

  return context
}