import { ConversationOverview } from "../conversations/service"
import { Collaborator } from "../service"
import { ScheduleDetailsType, ScheduleFile, ScheduleOverviewMutable, Term } from "./service"
import { ValidatableActionableNameableItem } from "../serviceImpl"

export class ScheduleOverview
  extends ValidatableActionableNameableItem
  implements ScheduleOverviewMutable
{
  projectID?: string
  projectName?: string

  constructor({ id, name, projectID, projectName, status }: ScheduleOverviewMutable) {
    super(id, name, status)

    this.projectID = projectID
    this.projectName = projectName
  }
}

export class ScheduleDetails implements ScheduleDetailsType
{
  collaboratorEditors: Array<Collaborator>
  collaboratorViewers: Array<Collaborator>
  conversations: Array<ConversationOverview>
  draft: string
  files: Array<ScheduleFile>
  paText: string
  terms: Array<Term>
  constructor({ collaboratorEditors, collaboratorViewers, conversations, draft, files, paText, terms }: ScheduleDetailsType) {
    this.collaboratorEditors = collaboratorEditors
    this.collaboratorViewers = collaboratorViewers
    this.conversations = conversations
    this.draft = draft
    this.files = files
    this.paText = paText
    this.terms = terms
  }
}